import { Component, Vue } from "vue-property-decorator";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import { utils } from "@/utils/utils";
import moment from "moment";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import { ReviewerpeerPreviewInterface } from "@/models/reviewer-peer-applications/reviewer-peers-applications.interface";
import EmailModalPopupComponent from "../email-modal-popup/email-modal-popup.component.vue";
import RevokeCertificatePopupComponent from "../revoke-certificate-popup/revoke-certificate-popup.component.vue";
import { PEER_FLAG_APPLICANT_QUERY } from "@/graphql/peer-flag-applicant";
import { gql } from "graphql-tag";
import { flagApplicantApi } from "@/providers/apis/peers-flag-applicant";
@Component({
  name: "reviewer-peers-preview",
  components: {
    AppLoaderComponent,
    PreviewPopupComponent,
    EmailModalPopupComponent,
    RevokeCertificatePopupComponent,
  },
})
export default class ReviewerPeersPreview extends Vue {
  public isLoading = false;
  public errorMessage = "";
  public profileData: any = [];
  public certificationData: any = [];
  public file: any = null;
  public showPreviewPopup = false;
  public tableHeader = [
    { key: "applicationTitle", label: "Application Name" },
    { key: "submittedAt", label: "Submission Date" },
    { key: "progressStatus", label: "Status" },
    { key: "action", label: "Action" },
  ];
  public applicationData = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public getCertificatesFilesPathUrl = "";
  public previewData: any[] = [];
  public emailData: any = null;
  public flagApplicantGrapqlRevoke: any = {};
  public flagHistoryDataApi: any = {};
  public disablePeerProfileEnable = false;
  public peerApplicationDisable: any;
  public thumnailData = "";
  public S3Path = "";
  public isShowImpersonate: any;
  public revokeUnrevokeCertificate = false;
  public peerRevokeUnRevoke: any;
  public created() {
    const id: any = this.$route.query.id;
    this.getCertificatesFilesPathUrl =
      appConfigService.getS3CertificatesFilesPathUrl();
    this.getPeerApplicationLists(id);
    this.flagApplicantGrapqlQuery();
    this.flagHistoryApi();
    this.S3Path = appConfigService.getS3FilesPathUrl();
  }

  public getPeerApplicationLists(id: string) {
    this.isLoading = true;
    getApplicationDetails
      .getPeersPreviewApplicationList({ id: id })
      .then((response) => {
        this.thumnailData = response.thumbnail;
        this.peerApplicationDisable = response.isDisabled;
        this.peerRevokeUnRevoke = response.isRevoked;
        this.applicationData = response.appplications.sort((a: any, b: any) => {
          return (
            new Date(b.submittedAt).getTime() -
            new Date(a.submittedAt).getTime()
          );
        });
        this.isLoading = false;
        this.profileData = this.applicationData.find(
          (data: ReviewerpeerPreviewInterface) => {
            return data.applicationName === "PART ONE";
          }
        );
        this.certificationData = this.applicationData.filter(
          (data: ReviewerpeerPreviewInterface) => {
            data.certificateFile = JSON.parse(data.certificateFile);
            if (
              data &&
              data.progressStatus === "ACCEPTED" &&
              data.applicationName === "PART TWO"
            ) {
              return data;
            }
          }
        );
        this.applicationData.forEach((data: ReviewerpeerPreviewInterface) => {
          if (data.applicationName === "PART ONE") {
            data.applicationTitle = "Training Application";
          }
          if (data.applicationName === "PART TWO") {
            data.applicationTitle = "Certification Application";
          }
          if (data.applicationName === "RENEWAL") {
            data.applicationTitle = "Renewal Application";
          }
          data.submittedAt = data.submittedAt
            ? moment(data.submittedAt).format("MM-DD-YYYY")
            : "--";
          data.expiryDate = data.expiryDate
            ? moment(data.expiryDate).format("MM-DD-YYYY")
            : "--";
        });
        this.isShowImpersonate = this.applicationData.filter(
          (application: any) => {
            return (
              application &&
              application.progressStatus === "ACCEPTED" &&
              application.applicationName === "PART ONE"
            );
          }
        );
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public backUrl() {
    let filter = "";
    if (this.$route.query.trainingcity) {
      filter += `&trainingcity=${this.$route.query.trainingcity}`;
    }

    if (this.$route.query.region) {
      filter += `&region=${this.$route.query.region}&regionId=${this.$route.query.regionId}`;
    }
    if (this.$route.query.age) {
      filter += `&age=${this.$route.query.age}&fromAge=${this.$route.query.fromAge}&toAge=${this.$route.query.toAge}`;
    }
    if (this.$route.query.education) {
      filter += `&education=${this.$route.query.education}`;
    }
    if (this.$route.query.gender) {
      filter += `&gender=${this.$route.query.gender}`;
    }

    return this.$route.query.isReport
      ? `/reviewer-dashboard/peers?type=${this.$route.query.type}&isReport=${this.$route.query.isReport}&fromDate=${this.$route.query.fromDate}&toDate=${this.$route.query.toDate}${filter}`
      : "/reviewer-dashboard/peers";
  }
  public previewForm(row: any) {
    let query;
    if (this.$route.query.isReport) {
      query = {
        id: this.$route.query.id,
        application: row.item.applicationName,
        type: this.$route.query.type,
        isReport: this.$route.query.isReport,
        fromDate: this.$route.query.fromDate,
        toDate: this.$route.query.toDate,
      };
      if (this.$route.query.trainingcity) {
        Object.assign(query, { trainingcity: this.$route.query.trainingcity });
      }
      if (this.$route.query.gender) {
        Object.assign(query, { gender: this.$route.query.gender });
      }
      if (this.$route.query.region) {
        Object.assign(query, { region: this.$route.query.region });
        Object.assign(query, { regionId: this.$route.query.regionId });
      }
      if (this.$route.query.age) {
        Object.assign(query, { age: this.$route.query.age });
        Object.assign(query, { fromAge: this.$route.query.fromAge });
        Object.assign(query, { toAge: this.$route.query.toAge });
      }
      if (this.$route.query.education) {
        Object.assign(query, { education: this.$route.query.education });
      }
    } else {
      query = {
        id: this.$route.query.id,
        application: row.item.applicationName,
      };
    }
    this.$router.push({
      path: `peers-preview/${row.item.id}`,
      query: query,
    });
  }
  public previewCertificationForm(content: any) {
    this.file = content.certificateFile;
    if (this.file) {
      const img = new Image();
      this.previewData = [
        {
          width: img.width,
          height: img.height,
          type: this.file.type,
          originalFileName: this.file.originalFileName,
          uploadedFileName: this.file.uploadedFileName,
        },
      ];
      this.showPreviewPopup = true;
    }
  }
  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }
  public onOpenModal(row: any) {
    this.emailData = row;
    this.$bvModal.show("email-modal");
  }
  public emailSentStatus(event: boolean) {
    if (event) {
      this.$bvModal.hide("email-modal");
    }
  }
  public revokeCertification() {
    this.revokeUnrevokeCertificate = true;
    this.disablePeerProfileEnable = false;
    this.$bvModal.show("revoke-certificate-modal");
  }
  public RevokeCertificatePopupClose() {
    this.$bvModal.hide("revoke-certificate-modal");
    const id: any = this.$route.query.id;
    this.getPeerApplicationLists(id);
    this.flagHistoryApi();
  }
  public flagHistoryApi() {
    flagApplicantApi
      .peersFlagHistory({ id: this.$route.query.id })
      .then((res) => {
        this.flagHistoryDataApi = res;
      });
  }
  public disablePeerProfile() {
    this.disablePeerProfileEnable = true;
    this.revokeUnrevokeCertificate = false;
    this.$bvModal.show("revoke-certificate-modal");
  }
  public flagApplicantGrapqlQuery() {
    this.$apollo
      .query({
        query: gql`query ${PEER_FLAG_APPLICANT_QUERY}`,
      })
      .then((result) => {
        this.flagApplicantGrapqlRevoke = result;
      });
  }
  public deleteProfilePic() {
    this.$bvModal.show("profile-pic-delete");
  }
  public cancelProfilePic() {
    this.$bvModal.hide("profile-pic-delete");
  }
  public submitProfilePic() {
    this.isLoading = true;
    getApplicationDetails
      .getPeersProfilePicDelete({ id: this.$route.query.id })
      .then((res) => {
        if (res.status == 204) {
          const id: any = this.$route.query.id;
          this.$bvModal.hide("profile-pic-delete");
          this.getPeerApplicationLists(id);
        }
      });
  }
  public impersonateUser() {
    const id: any = this.$route.query.id;
    getApplicationDetails.getImpersonate({ id: id }).then((response) => {
      const accessToken: any = localStorage.getItem("access_token");
      const userDetail: any = localStorage.getItem("user_details");
      localStorage.setItem("admin_access_token", accessToken);
      localStorage.setItem("admin_user_details", userDetail);
      localStorage.setItem("access_token", response.access_token);
      localStorage.setItem("user_details", JSON.stringify(response));
      localStorage.setItem("profileData", userDetail);
      this.$router.push({
        path: `/user-dashboard/home`,
        query: {
          id: id,
          type: "admin",
        },
      });
      localStorage.setItem("impersonateId", id);
      window.location.reload();
    });
  }
}
